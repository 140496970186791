import React from "react";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

const Donate = ({ data }: any) => {
  const dataHtml = data.donate.edges[0].node.html;
  const dataTitle = data.donate.edges[0].node.frontmatter.title;
  const theme = useTheme();

  return (
    <Layout>
      <Typography
        variant="h3"
        component="div"
        sx={{
          color: theme.palette.primary.main,
          marginBottom: theme.spacing(6),
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: dataTitle }} />
      </Typography>

      <Typography
        variant="body1"
        component="div"
        sx={{
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: theme.spacing(5)
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: dataHtml }} />
      </Typography>
    </Layout>
  );
};

export const query = graphql`
  query {
    donate: allMarkdownRemark(
      filter: { frontmatter: { title: { glob: "*Donate*" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`;

export default Donate;
